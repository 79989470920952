<template>
  <b-modal
    id="help-modal"
    hide-header
    hide-footer
    no-stacking
    size="lg"
  >
    <div class="header d-flex flex-row justify-content-between">
      <p class="title">Ajuda</p>
      <v-close @click="$bvModal.hide('help-modal')" />
    </div>
    <div class="body d-flex flex-column">
      <a class="help-link" v-for="link of links" v-bind:key="link.title"
        :href="link.url" target="_blank">
        <div class="outer">
          <div class="inner">
            <component v-bind:is="link.icon" />
          </div>
        </div>
        <div class="description">
          <p class="title">{{ link.title }}</p>
          <p class="subtitle">{{ link.subtitle }}</p>
        </div>
        <v-double-chevron-right />
      </a>
    </div>
  </b-modal>
</template>

<script>
import Close from '@/assets/icons/close.svg'
import DoubleChevronRight from '@/assets/icons/double-chevron-right.svg'
import HelpCircle from '@/assets/icons/help-circle.svg'
import VideoLine from '@/assets/icons/video-line.svg'

export default {
  components: {
    'v-close': Close,
    'v-double-chevron-right': DoubleChevronRight,
    'v-help-circle': HelpCircle,
    'new-video-line': VideoLine,
  },
  data() {
    return {
      links: [
        {
          title: 'Central de ajuda',
          subtitle: 'Artigos e tutoriais de uso da plataforma',
          icon: HelpCircle,
          url: 'https://docs.eyecarehealth.com.br/'
        },
        {
          title: 'Treinamento',
          subtitle: 'Realize treinamentos especializados em nossa plataforma',
          icon: VideoLine,
          url: 'https://docs.eyecarehealth.com.br/treinamentos'
        },
      ]
    }
  }
}
</script>

<style lang="scss">
#help-modal {
  .modal-dialog {
    width: 480px !important;
    height: 100vh !important;
    float: right;
    margin: 0 !important;
  }

  .modal-content {
    height: 100% !important;
    border: 0;
    border-radius: 16px 0 0 16px;
  }

  .modal-body {
    padding: 0 !important;
    height: 100%;

    .header {
      padding: 24px;
      border-bottom: 1px solid var(--neutral-200);
      align-items: center;

      .title {
        font-family: 'Red Hat Display';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 134%;
        letter-spacing: -0.01em;
        color: var(--type-active);
      }

      svg {
        width: 24px;
        height: 24px;
        fill: var(--neutral-600);
      }
    }

    .body {
      padding: 16px 0;

      .help-link {
        display: flex;
        align-items: center;
        justify-content: space-between;
        text-decoration: none;
        cursor: pointer;
        padding: 24px;
        border-bottom: 1px solid var(--neutral-200);
        gap: 19px;

        &:hover {
          background-color: var(--neutral-100);
        }


        .outer {
          display: flex;
          align-items: center;
          justify-content: center;
          aspect-ratio: 1;
          min-width: 72px;
          border-radius: 50%;
          background-color: #E7ECFE;

          .inner {
            display: flex;
            align-items: center;
            justify-content: center;
            aspect-ratio: 1;
            width: 48px;
            background-color: var(--blue-500);
            border-radius: 50%;

            svg {
              aspect-ratio: 1;
              width: 27px;
              stroke: white;
            }
          }
        }

        .description {
          width: 312px;

          .title {
            font-weight: 700;
            font-size: 18px;
            line-height: 134%;
            letter-spacing: -0.01em;
            color: var(--type-active);
          }

          .subtitle {
            font-weight: 600;
            font-size: 16px;
            line-height: 150%;
            color: var(--type-active);
          }
        }
      }
    }
  }
}
</style>
