<template>
  <div class="help-wrapper" @click="$bvModal.show('help-modal')">
    <div class="help-button">
      <Icon tooltip="Ajuda">
        <HelpCircle />
      </Icon>
    </div>
    <HelpModal />
  </div>
</template>

<script>
import Icon from '@/components/General/Icon'
import HelpModal from './HelpModal.vue'
import HelpCircle from '@/assets/icons/help-circle.svg'

export default {
  name: 'FilesModal',
  components: {
    Icon,
    HelpModal,
    HelpCircle
  },
}
</script>

<style lang="scss" scoped>
.help-wrapper {
  cursor: pointer;
}

.help-button {
  display: flex;
  gap: 10px;

  svg {
    width: 20px;
  }

  p {
    color: var(--dark-blue);
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
  }
}
</style>
